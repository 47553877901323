.product {
	display: flex;
	flex-direction: column;

	& > img {
		margin-bottom: $padding-rythm / 2;
		width: 135px;
		height: 135px;
	}
	&--title {
		margin: 0 !important;
	}

	&--description {
		margin: $padding-rythm / 2 0;
	}

	&--price {
		font-weight: bold;
	}

	@include from($bp-small) {
		flex-direction: row;

		& > img {
			margin-right: $padding-rythm / 2;
			margin-bottom: 0;
		}
	}
}
