.btn {
	@include linear-border();
	padding: 15px 20px;
	transition: all 200ms ease-in-out;
	outline: 0;
	border: none;
	background: $blue;
	color: white;

	&:hover,
	&:focus,
	&:active {
		background: white;
		color: black;
	}
}

.btn-group {
}
