.content-break {
	position: relative;

 	&--content {
		position: relative;
		z-index: 2;
	}

	&--bg {
		@include green-overlay();
	}

	&--content {
		& > .rich-text {
			margin-bottom: $padding-rythm / 2;
		}
	}

}
