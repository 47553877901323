.service {
	display: flex;
	flex-direction: column;

	+ .service {
		margin-top: $padding-rythm * 3.5;
	}

	&--icon {
		flex-basis: calc(100% * 0.15);
		max-width: calc(100% * 0.15);
		margin-bottom: 10px;

		& > svg {
			display: block;
			height: 90px;
			margin-right: $padding-rythm;
		}
	}

	&--text {
		flex-basis: calc(100% * 0.85);
	}

	&--description {
		margin: $padding-rythm / 2 0;
	}

	& .call-to-action {
		display: block;
	}

	@include from($bp-small) {
		flex-direction: row;

		&--icon {
			margin-bottom: 0;

			& > svg {
				height: 110px;
			}
		}

		&--title {
			margin-bottom: 0;
		}
	}
}
