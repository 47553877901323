// TODO consider changing this to: section > div selector
.responsive-section {
	@include responsive-component();
	padding: $padding-rythm * 2 0;

	&--content {
		> h2 {
			margin-bottom: $padding-rythm;
		}
	}

	@include from($bp-small) {
		padding: $padding-rythm * 3 0;

		&--content {
			> h2 {
				margin-bottom: $padding-rythm * 2;
			}
		}
	}
}
