.events-timeline {
	$timeline-colour: #bcbcbc;
	$circle-size-large: 44px;
	$circle-size-small: 24px;


	&--heading {
		margin-bottom: $padding-rythm * 2 !important;
	}

	&--icon {
		position: relative;
		& > svg {
			display: none;
			width: 100%;
		}
	}

	&--text {
		flex-basis: 0%;
		padding-left: $gutter / 2;
		padding-bottom: 3 * $padding-rythm;
		border-left: 1px solid $timeline-colour;
		overflow: hidden;
		flex-basis: calc(100% * 0.90);
		transition: all 1s ease-in-out;
		opacity: 0;
		z-index: 1;

		& .call-to-action {
			display: block;
			margin-top: $padding-rythm / 2;
		}
	}

	&--circle {
		position: absolute;
		background-color: white;
		width: 0px;
		height: 0px;
		border-radius: 100%;
		top: 0;
		transition: all 300ms ease-in-out;
		width: 0px;
		height: 0px;
		right: 0;
		z-index: 2;
	}

	&--title {
		@include h3-reset();
		color: $text-colour !important;
	}

	&--date {
		margin: 7px 0;
	}

	&--date,
	&--subtitle {
		display: block;
		line-height: 1;
	}

	&--description {
		margin: $padding-rythm / 2 0 0 0;
	}

	&--view-more {
		left: 1px;
		display: inline-block;
	}

	&--event {
		display: flex;
	}

	&:not([data-timeline-animation]),
	&--event.is-active {
		.events-timeline {
			&--circle {
				border: 1px solid $timeline-colour;
				width: $circle-size-small;
				height: $circle-size-small;
				right: -$circle-size-small / 2;
			}

			&--text {
				opacity: 1;
			}
		}
	}

	@include from($bp-small) {
		&--icon {
			margin-right: $gutter;
			flex-basis: calc(100% * 0.10);
			& > svg {
				display: block;
				max-width: 95px;
			}
		}

		&--text {
			padding-left: $gutter;
			flex-basis: calc(100% * 0.75);
		}

		&--circle {
			right: -$gutter;
			top: -11px;
		}

		&--view-more {
			left: calc(#{100% * 0.10} + #{$gutter});
			transform: translateX(-50%);
		}

		// no description
		&--subtitle {
			+ .call-to-action {
				margin-top: $padding-rythm;
			}
		}

		&:not([data-timeline-animation]),
		&--event.is-active {
			.events-timeline {
				&--circle {
					width: $circle-size-large;
					height: $circle-size-large;
					right: -($gutter + ($circle-size-large /2 ));
				}
			}
		}
	}

	&__remove-last {
		.events-timeline--event:last-of-type {
			.events-timeline--text {
					border-left: 0;
				}
			}
	}
}
