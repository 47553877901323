.l-content {
	&--title {
		margin-bottom: $padding-rythm;
	}

	&--body {
		.panel--background,
		.content-image,
		.content-break {
			clear: both;
		}

		.content-break,
		.panel--background {
			width: calc(100% + #{$gutter});
			margin-left: -$gutter / 2;
		}

		.content-image {
			&__left,
			&__right {
				clear: none;
			}
		}
	}

	@include from($bp-small) {
		.content-break,
		.panel--background {
			@include bust-out();
		}
	}

	&--form {
		margin-top: $padding-rythm;
	}
}
