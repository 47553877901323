form {
	.form-input {
		@include linear-border();
		width: 100%;
		margin-left: 1px;

		& > input,
		& > textarea {
			width: 100%;
			outline: 0;
			border: 0;
			border-radius: 0;
			padding: $padding-rythm / 1.5 $padding-rythm / 2;
			z-index: 1;
			position: relative;

			@include placeholder() {
				color: $text-colour;
				opacity: 1;
				font-weight: bold;
			}

			&[value]:not([value=""]),
			&:focus {
				+ label {
					font-size: 12px;
					top: 0px;
					left: 4px;
				}
			}
		}

		& > svg {
			position: absolute;
			z-index: 1;
			height: 16px;
			width: 32px;
			right: 14px;
			top: calc(50% - 8px);
			cursor: pointer;
		}

		& > label {
			position: absolute;
			z-index: 2;
			top: $padding-rythm / 1.5;
			left: $padding-rythm / 2;
			transition: all 150ms ease-in-out;
		}

		&__select {
			& > select {
				padding: $padding-rythm / 2.5;
				width: 100%;
				background: white;
				outline: 0;
				border: 0;
			}

			& ~ label {
				display: block;
				margin-top: $padding-rythm / 2;
			}
		}

		+ .form-input,
		+ .btn {
			margin-top: $padding-rythm / 2;
		}

		&.uppercase {
			& > label {
				text-transform: uppercase;
			}
		}

		&.flex {
			flex-basis: 100%;
			width: auto;

			@include from($bp-small) {
				flex-basis: calc((100% / 3) - 15px);
				margin: 0;
			}
		}
	}

	.flex-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin: $padding-rythm / 2 0;
	}
	.form-boolean-group {
		border: 0;
		padding: 0;
		margin-top: $padding-rythm / 2;

		&--label {
			margin-bottom: 5px;
		}
	}

	.form-boolean {
		position: relative;
		display: flex;

		& > input {
			position: absolute;
			opacity: 0;
			z-index: -1;
			left: 0;
			top: 0;
			width: 30px;
			height: 30px;

			&:checked + label:after {
				background: $blue;
				border: 1px solid white;
			}

			&:hover:not(:checked) + label:after {
				background: $blue;
				border: 5px solid white;
			}

			&[type="radio"] {
				+ label {
					&:after,
					&:before {
						border-radius: 10px;
					}

					&:after {
						top: 4px;
					}

					&:before {
						top: 2px;
					}
				}
			}
		}
		&__single {
			margin: $padding-rythm / 2 0;
		}

		& > label {
			$size: 20px;
			position: relative;
			padding-left: 30px;

			&:before {
				z-index: 1;
				background: $blue;
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 3px;
				height: $size;
				width: $size;
			}

			&:after {
				z-index: 2;
				content: "";
				display: block;
				position: absolute;
				background: white;
				border: 5px solid white;
				left: 2px;
				top: 5px;
				height: $size - 4px;
				width: $size - 4px;
				transition: all 150ms linear;
			}
		}
	}

	.form-error {
		color: red;
		font-weight: bold;
	}

	.form-help {
		font-size: $fs-body-sm;
		color: lighten($text-colour, 10%);
		margin-bottom: $padding-rythm / 2;
		margin-top: 5px;
	}
}
