.news-item {
	display: flex;
	flex-direction: column;
	padding: $padding-rythm 0;
	border-bottom: 2px solid $blue;

	&:first-of-type {
		padding-top: 0;
	}

	&--date {
		flex-basis: calc(100% * 0.25);
		font-weight: bold;
	}

	&--text {
		flex-basis: calc(100% * 0.75);

		& > p {
			margin-bottom: $padding-rythm;
		}
	}

	&--container {
		margin-bottom: $padding-rythm;
	}

	@include from($bp-small) {
		flex-direction: row;
		padding: $padding-rythm * 1.5 0;

		&--date {
			& > p {
				white-space: pre-wrap;
			}
		}
	}
}
