footer {
	position: relative;
	clear:both;

	&:after {
		content: '';
		position: absolute;
		background: $blue;
		height: 1px;
		z-index: -1;
		top: -1px;
		width: 100%;
	}

	@include from($bp-small) {
		&:after {
			width: calc(100% - (2 * #{$gutter}));
			left: $gutter;
		}
	}
}

.footer {
	clear: both;
	display: flex;
	flex-wrap: wrap;
	font-size: $fs-body-sm;

	&--column {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		margin-bottom: $padding-rythm;

		& > h3 {
			margin-bottom: $padding-rythm;
			height: 32px; // Same size as social media icons
			vertical-align: bottom;
		}
	}

	&--social-medias {
		margin-bottom: $padding-rythm;
		& > a {
			display: inline-block;
			background: black;
			margin-right: $padding-rythm / 2;
			& > svg {
				transform: scale(1.05); // Hide overflow on FF and IE
				display: block;
				width: 32px;
				height: 32px;
			}
		}
	}

	&--link {
		@include site-nav();
		font-weight: bold;
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&--meta {
		margin-top: auto;

		& > a {
			margin-bottom: 10px;
		}
		&-link {
			display: block;
			color: lighten(black, 30%);
		}
	}

	&--signup-form {
		margin-bottom: $padding-rythm;
	}
}

@include from($bp-small) {
	.footer {
		padding: 4 * $padding-rythm 0;

		&--link {
			margin-bottom: 15px;
		}

		&--column {
			flex-basis: 50%;
			margin-bottom: 0;
		}

		&--social-medias {
			align-self: flex-end;
			& > a {
				margin-left: 16px;
				margin-right: 0;
			}
		}

		&--signup-form {
			margin-bottom: 0;
		}

		&--meta {
			& > a {
				margin-bottom: 15px;
			}
		}
	}
}
