.l-payment {
	&--title {
		padding: $gutter * 2 0;
	}

	& h3 {
		margin-top: 2 * $padding-rythm;
		margin-bottom: $padding-rythm;
	}

	&--thanks {
		opacity: 0;
		height: 0;

		&.is-active {
			opacity: 1;
			height: auto;
			transition: opacity 300ms linear;
		}
	}

	&--option {
		&__expanded {
			margin-top: 2 * $padding-rythm;
		}
	}
}
