.call-to-action {
	font-size: $fs-body-sm;
	font-weight: bold;
	> svg {
		display: inline-block;
		height: 16px;
		width: 32px;
		margin-bottom: -3px;
		margin-left: 6px;
	}
}
