@mixin responsive-component() {
	margin: 0 $gutter / 2;

	&--content {
		width: 100%;
		margin: 0 auto;
	}

	@include from($bp-small) {
		// margin: 0 $gutter;
		margin: 0 auto;
		&--content {
			width: 60%;
		}
	}
}

@mixin site-nav() {
	& > a {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: 0;
			left: 0;
			background: $blue;
			opacity: 0;
			width: 0;
			transition: all 300ms ease-in-out;
		}

		&:hover {
			&:after {
				opacity: 1;
				width: 100%;
			}
		}
	}
}

%bg-cover {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

%absolute-fill {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

@mixin linear-border() {
	position: relative;
	&:after {
		content: '';
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		position: absolute;
		top: -1px;
		left: -1px;
		background: $blue;
		z-index: -1;
	}
}


@mixin green-overlay() {
	@extend %absolute-fill;

	& > div {
		@extend %bg-cover;
		@extend %absolute-fill;
		opacity: 0.58;
	}
	&:after {
		@extend %absolute-fill;
		content: '';
		z-index: 1;
		background: $blue;
		opacity: 0.82;
	}
}


@mixin richtext-elements {
	p, ul, ol, hr {
		@content;
	}
}
