.site-nav {
	height: 100%;
	display: flex;
	align-items: center;
	&--list {
		display: none;
		height: 100%;
		font-size: $fs-body-sm;
	}

	&--item {
		@include site-nav();
		height: 100%;
		margin: 0 30px;
		display: flex;
		align-items: center;
		position: relative;
		font-weight: bold;
		line-height: 1.2;

		&:last-of-type {
			margin-right: 0;
		}

		&__current > a {
			&:after {
				opacity: 1;
				width: 100%;
			}
		}

		& > a:not(:only-child):hover:after {
			opacity: 0;
		}

		&:hover {
			.header-menu {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&--hamburger {
		$size: 12px;
		$stripe: 2px;
		$offset: ($size / 2) - ($stripe / 2);
		width: $size;
		height: $size;
		position: relative;
		cursor: pointer;

		&:before, &:after {
			transition: $transition-default;
			content: '';
			width: 100%;
			position: absolute;
			left: 0;
			height: $stripe;
			display: block;
		}

		&:before {
			background-color: black;
			top: 0;
			box-shadow: 0px $offset black;
		}

		&:after {
			bottom: 0;
			background-color: black;
		}

		&.is-active {
			&:before {
				transform: translateY($offset) rotate(135deg);
				box-shadow: 0px $offset transparent;
			}
			&:after {
				transform: translateY(-$offset) rotate(-135deg);
			}
		}
	}

	@include from($bp-small) {
		&--list {
			display: flex;
		}

		&--hamburger {
			display: none;
		}
	}
}
