.content-image {
	width: calc(100% + #{$gutter});
	margin-left: -$gutter / 2;

	&__has-caption {
		@include linear-border();
		&:after {
			height: 1px;
			top: auto;
		}
	}

	&--caption {
		font-style: italic;
		padding: $padding-rythm / 2 $gutter / 2;
		line-height: 1.2;
	}

	&__left,
	&__right {
		& > img {
			width: 100%;
			height: auto;
		}
	}


	@include from($bp-small) {
		width: auto;
		max-width: 100%;
		margin-left: 0;
		display: inline-block;
		margin-right: $padding-rythm / 2;

		&--caption {
			padding: $padding-rythm / 2 0;
		}

		&__left,
		&__right {
			max-width: 500px; // ??
			margin: $padding-rythm;
			margin-top: 7px; // So that if it's at the top it lines up perfectly with the text (not line height) -JS

			&.content-image__has-caption {
				margin-bottom: $padding-rythm * 1.5;
			}
		}

		&__left {
			float: left;
			margin-left: calc(-100% / 3 + #{$gutter});
		}

		&__right {
			float: right;
			margin-right: calc(-100% / 3 + #{$gutter});
		}

		&__full-width {
			@include bust-out();
			max-width: none;

			& > img {
				width: 100%;
				height: auto;
			}

			.content-image {
				&--caption {
					margin: 0 auto;
					width: 60%;

				}
			}

			&:after {
				width: 60%;
				margin: 0 auto;
				left: auto;
				transform: translateX(100% / 3);
			}
		}
	}
}
