.full-page-menu {
	position: fixed;
	top: 89px;// Size of header -1px to hide the line
	right: 0;
	height: calc(100% - #{$header-height-sm} + 1px);
	width: 0;
	opacity: 0;
	pointer-events: none;
	background-color: white;
	z-index: 100;
	transition: $transition-default;
	padding: 0 $gutter / 2;

	display: flex;
	justify-content: space-between;
	flex-direction: column;
	overflow-y: scroll;

	&.is-active {
		opacity: 1;
		pointer-events: all;
		width: 100%;
	}

	&--list {
		padding: $gutter 0;

		&-item {
			@include site-nav();
			font-size: $fs-body-lg;
			font-weight: bold;
			margin-bottom: 5px;
		}
	}

	&--signup-form {
		margin-bottom: $padding-rythm / 2;
	}

	&--social-medias {
		margin-bottom: $padding-rythm;
		& > a {
			display: inline-block;
			background: black;
			margin-right: 16px;
			& > svg {
				display: block;
				width: 32px;
				height: 32px;
			}
		}
	}

	&--meta {
		&-link {
			display: block;
			margin: 10px 0;
		}
	}

	&--sub-list {
		max-height: 0;
		overflow: hidden;
		transition: max-height 300ms linear;
		padding-left: $padding-rythm / 2;
		& > li {
			font-weight: normal;
			font-size: $fs-body;
		}

		&.is-active {
			max-height: 500px; // Should be big enough to cover most cases
		}
	}

	&--opener {
		& > svg {
			transition: transform 150ms linear;
			width: 16px;
			height: 16px;
			display: inline;
			margin-bottom: -2px;
		}

		&:after {
			opacity: 0 !important;
		}

		&.is-active {
			& > svg {
				transform: rotate(-180deg);
			}
		}
	}

	&--meta-link {
		font-size: 16px;
	}

	&--child-link {
		margin-left: $padding-rythm;
		&:before {
			content: '-';
		}
	}
}
