.two-col-block {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&--aside,
	&--main {
		flex-basis: 100%;
	}

	&--aside {
		margin-bottom: 1.5em;
	}

	@include from($bp-small) {
		&--aside {
			flex-basis: calc((100% / 3) - #{$padding-rythm} / 2);
			margin-bottom: 0;
		}

		&--main {
			flex-basis: calc(((100% / 3) * 2) - #{2*$padding-rythm});
		}
	}
}
