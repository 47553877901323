.image-grid {
	overflow: hidden;
	&--container {
		display: flex;
		flex-wrap: wrap;
	}

	&--image {
		@include flex-grid-item(2, 10px);
		& > img {
			width: 100%;
			height: auto;
		}

		@include from($bp-small) {
			@include flex-grid-item(3, $padding-rythm / 2);
		}

		@include from($bp-medium) {
			@include flex-grid-item(4, $padding-rythm / 2);
		}
	}
}
