.header {
	height: $header-height-sm;
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;

	padding: 0 $gutter / 2;
	display: block;

	&--inner {
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;


	}

	&:not(.hide-line):after {
		content: '';
		position: absolute;
		background: $blue;
		height: 1px;
		bottom: 0;
		width: calc(100% - #{$gutter});
		left: $gutter / 2;
	}

	&--logo {
		display: block;
		& > svg {
			display: block;
			height: $header-height-sm;
			width: auto;
			padding: 10px 0;
			left: 0;
		}
	}


}

@include from($bp-small) {
	.header {
		height: $header-height;
		padding: 0 $gutter;

		&--logo {

			& > svg {
				height:  $header-height;
				max-width: 110px;
				padding: $gutter / 4 0;
			}
		}

		&:not(.hide-line):after {
			width: calc(100% - (2 * #{$gutter}));
			left: $gutter;
		}
	}
}
