body {
	font-size: $fs-body;
	font-family: $body-font;
	font-weight: lighter;
	line-height: 35px;
	line-height: 1.6;
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1;
	font-family: $heading-font;
	margin: 0;
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: $text-colour;
	display: inline-block;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid $blue;
	&:hover,
	&:focus,
	&:active {
		border-color: $blue;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

h1 {
	font-size: $fs-h2;
	font-weight: bold;
}

h2 {
	font-size: 40px;
	font-weight: bold;
}

h3 {
	font-size: $fs-body-lg;
	font-weight: bold;
}

@mixin h3-reset($c: $text-colour) {
	background: none;
	background-clip: inherit;
	-webkit-backround-clip: inherit;
	color: $c;
}



h3 {}

h4 {}

h5 {}

a {
	color: $text-colour;
	display: inline;
	&:hover,
	&:focus,
	&:active {
	}
}

ul, ol {
	margin: 0;
}

ul {
	list-style: none;
	padding: 0;
}

p {
	margin: 0;
}

a {
	text-decoration: none;
}


.rich-text {

	> * {
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}

		+ .responsive-object,
		+ table,
		+ .two-col-block,
		+ .panel--background,  // TODO: This should be .panel. Fix .panel to have .panel as top level class.
		+ .events-timeline {
			margin-top: $padding-rythm * 2;
			margin-bottom: $padding-rythm * 2;
		}
	}

	> h2, h3, h4 {
		margin-bottom: 0.75em;
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	hr {
		border: 0;
		position: relative;
		&:after {
			content: '';
			display: block;
			background: $blue;
			width: 100%;
			height: 1px;
		}
	}

	table {
		min-width: 100%;
		font-size: 16px;

		tr {
			+ tr {
				position: relative;

				&:after {
					content: '';
					width: 100%;
					height: 1px;
					left: 0;
					bottom: 100%;
					background: $blue;
					position: absolute;
				}
			}
		}

		th {
			width: 100px;
			text-align: left;
		}

		td, th {
			padding: $padding-rythm/2 0;
			line-height: 1.5;
			vertical-align: top;
			+ td,
			+ th {
				// border-left: 1px solid $text-colour;
				padding: $padding-rythm/2;
			}
		}
	}

	ul {
		list-style-image: url('../images/dot.png');
	}

	ul, ol {
		padding-left: 1em;
	}

	h3 {
		font-size: $fs-body-lg;
		font-weight: bold;
		background: $blue;
		background-clip: text;
		-webkit-background-clip: text;
		max-width: 70%;
		color: transparent;
	}

	.responsive-object {
		position: relative;

		& > iframe,
		& > img {
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}
}


_:-ms-fullscreen,
:root .rich-text h3 {
	@include h3-reset($blue);
}


@include from($bp-small) {
	h1 {
		font-size: $fs-h1;
	}
	h2 {
		font-size: $fs-h2;
	}
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
