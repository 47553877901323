*,
*:before,
*:after {
	box-sizing: border-box;
}

html {}

body {
	color: $text-colour;
	padding-top:  $header-height-sm;
	&.menu-open {
		height: 100vh;
		overflow: hidden;
	}

	@include from($bp-small) {
		padding-top:  $header-height;
	}
}
