.panel {
	@include responsive-component();
	color: white;
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;

	&--background {
		@extend %bg-cover;
		height: 350px;
		background-color: rgba(0, 0, 0, 0.2);
		background-blend-mode: multiply;

		&__linear {
			background-color: inherit;
			position: relative;
		}
	}

	&--overlay {
		z-index: 1;
		@extend %absolute-fill;
		opacity: 0.84;
		background: linear-gradient(to right,  rgba(0, 0, 0, 0.84), transparent);
	}

	&--bold-title,
	&--light-title {
		font-size: $fs-h2;
		width: 100%;
		line-height: 1;
	}

	&--light-title {
		font-weight: lighter;
	}

	&--logo {
		& > img {
			width: 100px;
			height: auto;
		}
	}

	& .call-to-action {
		color: white;
		font-size: 10px;

		& > svg {
			fill: white;
			height: 13px;
			width: 26px;
		}
	}

	@include from($bp-small){
		&--bold-title,
		&--light-title {
			font-size: 70px;

			&__large {
				font-size: $fs-h1-large;
			}
		}

		&--background {
			height: 600px;
		}

		&--logo {
			margin-bottom: $padding-rythm / 4;

			> img {
				width: 175px;
			}
		}


		&--content {}

		.call-to-action {
			font-size: $fs-body-sm;
			& > svg {
				height: 16px;
				width: 32px;
			}
		}
	}
}
