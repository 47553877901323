@mixin placeholder() {

	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		// Firefox 18-
		@content;
	}

	&::-moz-placeholder {
		// Firefox 19+
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}
}

// Use on components that need to take up full width but are contrained by the 60% limit
@mixin bust-out {
	width: calc(100% + ( 2 / 3 * 100%));
	margin-left: calc(-100% / 3);
}

@mixin flex-grid-item($col-count, $gutter-size) {
	flex-basis: calc(#{100% / $col-count} - #{$gutter-size * ($col-count - 1) / $col-count});
	&:nth-child(n){
		margin-right: $gutter-size;
		margin-top: 0;
	};
	&:nth-child(#{$col-count}n) {
		margin-right: 0;
	}
	&:nth-child(n+#{$col-count+1}) {
		margin-top: $gutter-size;
	}
};
