.message {
	position: absolute;
	top: $header-height-sm;
	left: 50%;
	transform: translateX(-50%);
	background: $blue;
	font-weight: bold;
	padding: $padding-rythm / 2 $padding-rythm;
	border-bottom-left-radius: 9px;
	border-bottom-right-radius: 9px;
	color: white;

	@include from($bp-small) {
		top: $header-height;
	}
}
