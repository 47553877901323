.header-menu {
	opacity: 0;
	transition: $transition-default;
	pointer-events: none;
	transform: translateX(-50%);
	left: 50%;
	position: absolute;
	background: $blue;
	padding: $padding-rythm;
	border-radius: 5px;
	z-index: 20;
	top: 76px;
	font-weight: lighter;

	&--item {
		margin-bottom: $padding-rythm / 4;
		white-space: nowrap;

		&__has-sublist {
			margin-bottom: $padding-rythm / 2;
		}

		&:last-child {
			margin-bottom: 0;
		}

		& > a {
			display: block;
		}

		&:before,
		& > a {
			transition: color 150ms ease-in-out;
		}

		&:hover {
			& > a,
			&:before {
				color: white;
			}
		}
	}

	&--sublist {
		padding-left: 6px;

		& .header-menu {
			&--item a {
				&:before {
					content: '–';
				}
			}
		}
	}

	&:after {
		content: '';
		width: 10px;
		height: 10px;
		position: absolute;
		background-color: $blue;
		transform: translateX(-50%)rotate(45deg);
		left: 50%;
		top: -5px;
		z-index: 10;
	}
}
