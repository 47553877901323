.l-home {
	&--services-title {
		margin-top: $padding-rythm * 2;
	}

	&--call-to-actions {
		padding-top: $padding-rythm / 2;
		padding-bottom: $padding-rythm;
		& > div {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}

	&--cta {
		flex-basis: 100%;
		background: $blue;
		padding: $padding-rythm / 2.5;
		padding-right: $padding-rythm / 2.5 + 37px;
		font-weight: bold;
		color: white;
		position: relative;
		font-size: $fs-body-sm;
		line-height: 1.2;

		& > svg {
			fill: white;
			display: inline-block;
			height: 14px;
			width: 28px;
			position: absolute;
			right: $padding-rythm / 2;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			text-decoration: underline;
		}
	}


	@include from($bp-small) {
		&--services-title {
			margin-top: $padding-rythm * 4;
		}

		&--cta {
			flex-basis: calc(50% - 10px);
			max-width: 49%; // IE Fix
		}
	}
}
